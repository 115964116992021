import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { format } from "date-fns"
import { PrintComponent, PrintAllComponent } from "./PrintQRComponent"
import QRCode from "react-qr-code"

import { Container, Divider, Header, Icon, Button, Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Confirm, Popup, Grid, GridRow, Message, CardGroup, Card, CardContent, CardHeader, CardMeta, CardDescription, Image, Form, FormField, Select, TextArea, Input } from 'semantic-ui-react'

const options = [
    { key: 'audit-working-paper', text: 'Audit Working Paper', value: 'Audit Working Paper' },
    { key: 'arch-file', text: 'Arch File', value: 'Arch File' },
    { key: 'paper-file', text: 'Paper File', value: 'Paper File' },
    { key: 'envelope', text: 'Envelope', value: 'Envelope' },
    { key: 'cheque-book', text: 'Cheque Book', value: 'Cheque Book' },
    { key: 'cheque-counter-foil', text: 'Cheque Counter Foil', value: 'Cheque Counter Foil' },
    { key: 'clear-file', text: 'Clear File', value: 'Clear File' },
    { key: 'bundle-documents', text: 'Bundle Documents', value: 'Bundle Documents' },
    { key: 'binding-report', text: 'Binding Report', value: 'Binding Report' },
    { key: 'accounting-report', text: 'Accounting Report', value: 'Accounting Report' },
    { key: 'signing-documents', text: 'Signing Documents', value: 'Signing Documents' },
    { key: 'others', text: 'Others', value: 'Others' },
  ]

class ClientViewDoc extends Component {
    state = { showDocHandoverConfirmationPopup: false, showDocReceivedConfirmationPopup: false, showAuditTrailDetails: false, isEditing: false, editingIndex: 0, type: "", description: "", remarks: "", newType: "", newDescription: "", newRemarks: "" }

    constructor(props) {
        super(props)
        this.handleDocHandoverConfirm = this.handleDocHandoverConfirm.bind(this)
        this.handleDocHandoverCancel = this.handleDocHandoverCancel.bind(this)
        this.handleDocReceivedConfirm = this.handleDocReceivedConfirm.bind(this)
        this.handleDocReceivedCancel = this.handleDocReceivedCancel.bind(this)
        this.handleRefreshClicked = this.handleRefreshClicked.bind(this)
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleChangeTypeDropDown = e => {
        this.setState({ type: e.target.innerText })
    }

    handleChangeNewTypeDropDown = e => {
        this.setState({ newType: e.target.innerText })
    }

    renderTableRows() {
        if (!!this.props.recordToView) {
            var rows = []
            for (var i = 0; i < this.props.recordToView.docs.length; i++) {
                let doc = this.props.recordToView.docs[i]
                var divs = []
                if (this.state.showAuditTrailDetails) {
                    for (const trail of doc.auditTrail) {
                        var str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " by " + trail.user + ".\n"
                        if (!!trail.initials) {
                            if (trail.status.includes('Returned')) {
                                str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " (e-Signature obtained from client by " + trail.initials + ").\n"
                            } else {
                                str = trail.status + " at " + format(trail.date, "d MMMM, yyyy 'at' hh:mm a") + " (QR code scanned by " + trail.initials + ").\n"
                            }
                        }
                        divs.push(<p style={{ marginTop: 0, marginBottom: 5 }}>{str}</p>)
                    }
                } else {
                    divs = ['...']
                }
                if (this.state.isEditing && this.state.editingIndex === i) {
                    const { type, description, remarks } = this.state
                    rows.push(
                        <TableRow key={i}>
                            <TableCell collapsing>
                            </TableCell>
                            {this.renderActionButtonsColumn(i, doc)}
                            <TableCell collapsing>{i+1}</TableCell>
                            <TableCell>
                                <Form>
                                    <FormField control={Select} name='type' value={type} defaultValue={type} options={options} placeholder='Select one' onChange={this.handleChangeTypeDropDown}/>
                                </Form>
                            </TableCell>
                            <TableCell>
                                <Form>
                                    <FormField control={TextArea} rows='1' name='description' value={description} placeholder='' onChange={this.handleChangeField}/>
                                </Form>
                            </TableCell>
                            <TableCell>
                                <Form>
                                    <FormField control={Input} name='remarks' value={remarks} onChange={this.handleChangeField}/>
                                </Form>
                            </TableCell>
                            <TableCell>{doc.status}</TableCell>
                            <TableCell style={{ whiteSpace: 'pre-line' }}>{divs}</TableCell>
                        </TableRow>
                    )
                } else {
                    rows.push(
                        <TableRow key={i}>
                            <TableCell collapsing>
                            <PrintComponent clientID={this.props.clientID} recordID={this.props.recordToView.id} doc={doc} displayStr={i+1 + ". " + doc.type} />
                            </TableCell>
                            {this.renderActionButtonsColumn(i, doc)}
                            <TableCell collapsing>{i+1}</TableCell>
                            <TableCell>{doc.type}</TableCell>
                            <TableCell>{doc.description}</TableCell>
                            <TableCell>{doc.remarks}</TableCell>
                            <TableCell>{doc.status}</TableCell>
                            <TableCell style={{ whiteSpace: 'pre-line' }}>{divs}</TableCell>
                        </TableRow>
                    )
                }
            }
            if (this.state.addingNewDoc) {
                const { newType, newDescription, newRemarks } = this.state
                rows.push(
                    <TableRow key='new'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell collapsing>{i+1}</TableCell>
                        <TableCell>
                            <Form>
                                <FormField control={Select} name='newType' value={newType} defaultValue={newType} options={options} placeholder='Select one' onChange={this.handleChangeNewTypeDropDown}/>
                            </Form>
                        </TableCell>
                        <TableCell>
                            <Form>
                                <FormField control={TextArea} rows='1' name='newDescription' value={newDescription} placeholder='' onChange={this.handleChangeField}/>
                            </Form>
                        </TableCell>
                        <TableCell>
                            <Form>
                                <FormField control={Input} name='newRemarks' value={newRemarks} onChange={this.handleChangeField}/>
                            </Form>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                )
            }
            return rows
        }
    }

    renderActionButtonsColumn(i, doc) {
        if (this.props.canEditDocs) {
            if (this.state.isEditing && this.state.editingIndex === i) {
                return (
                    <TableCell collapsing>
                        <Button circular basic icon='check' color='green' onClick={this.handleDoneEditing.bind(this, i, doc)} />
                        <Button circular basic icon onClick={this.handleDeleteDocClicked.bind(this, i, doc)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteDocCancel} onConfirm={this.handleDeleteDocConfirm} header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                )
            } else {
                return (
                    <TableCell collapsing>
                        <Button circular basic icon='pencil' onClick={this.handleEditClicked.bind(this, i, doc)} />
                        <Button circular basic icon onClick={this.handleDeleteDocClicked.bind(this, i, doc)}>
                            <Confirm open={this.state.showDeleteConfirmationPopup} onCancel={this.handleDeleteDocCancel} onConfirm={this.handleDeleteDocConfirm} header="Confirmation" content={this.state.deleteConfirmContentString} cancelButton="Cancel" confirmButton="Delete"/>
                            <Icon name="trash alternate" />
                        </Button>
                    </TableCell>
                )
            }
        }
    }

    renderActionButtonsHeader() {
        if (this.props.canEditDocs) {
            return (
                <TableHeaderCell></TableHeaderCell>
            )
        }
    }

    renderTable() {
        if (this.props.recordToView.docs.length === 0) {
            return <h3>No documents to show</h3>
        }
        var str = "Show details"
        if (this.state.showAuditTrailDetails) str = "Hide details"
        return (
            <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell></TableHeaderCell>
                        {this.renderActionButtonsHeader()}
                        <TableHeaderCell>No</TableHeaderCell>
                        <TableHeaderCell width='3'>Type</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell width='3'>Remarks</TableHeaderCell>
                        <TableHeaderCell width='2'>Status
                            <Popup wide trigger={<Icon style={{ marginLeft: 5 }} circular name="info" size="small" />} position="bottom left">
                                <Grid style={{ paddingLeft: 16, paddingRight: 16 }} centered>
                                <GridRow>
                                    <Header as='h4'>Received</Header>
                                    Document has just been received from the client. Pending print and stick QR code on the document.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>In Processing</Header>
                                    Document has already been handed over to the Audit Team, and they are now processing it.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>Ready to Return</Header>
                                    Document has been received from the Audit Team, and its QR code has already been scanned.
                                </GridRow>
                                <GridRow textAlign='center'>
                                    <Header as='h4'>Returned</Header>
                                    Document has been returned to the client, and the client's e-signature has been received.
                                </GridRow>
                                </Grid>
                            </Popup>
                        </TableHeaderCell>
                        <TableHeaderCell width='3'>Audit Trail <Button style={{ marginLeft: 10 }} basic color="black" size="tiny" onClick={() => this.handleAuditTrailShowDetailsClicked()}>{str}</Button></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                {this.renderTableRows()}
                </TableBody>
            </Table>
        )
    }

    renderActionButtons() {
        if (this.props.viewOnly) return
        var showHandoverButton = false
        var showReceivedButton = false
        var showGenerateESignButton = false
        var isPartialReturn = false
        for (const doc of this.props.recordToView.docs) {
            if (doc.status === 'Received') {
                showHandoverButton = true
                break
            } else if (doc.status === 'In Processing') {
                showReceivedButton = true
            } else if (doc.status === 'Ready to Return') {
                showGenerateESignButton = true
            }
        }
        var rows = []
        if (showReceivedButton && showGenerateESignButton) isPartialReturn = true
        if (showHandoverButton) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocHandoverClicked()}>I have handed over All Documents<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
        } else if (isPartialReturn) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocReceivedClicked()}>I have received all Remaining Documents<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
        } else if (showReceivedButton) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='green' onClick={() => this.handleRefreshClicked()}>Refresh Documents Status<Icon style={{ marginLeft: 10 }} name="refresh" /></Button>)
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='red' onClick={() => this.handleDocReceivedClicked()}>I have received All Documents<Icon style={{ marginLeft: 10 }} name="check" /></Button>)
        } else if (showGenerateESignButton) {
            rows.push(<Button style={{ marginTop: 20, marginRight: 15 }} color='green' onClick={() => this.handleRefreshClicked()}>Refresh Documents Status<Icon style={{ marginLeft: 10 }} name="refresh" /></Button>)
        }
        return rows
    }

    renderAddDocumentButton() {
        if (this.props.canEditDocs) {
            let str = this.state.addingNewDoc ? 'Save New Document' : 'Add New Document'
            let color = this.state.addingNewDoc ? 'green' : 'black'
            return <Button style={{ marginTop: 10, marginBottom: 10 }} basic={!this.state.addingNewDoc} color={color} onClick={() => this.handleAddNewDocumentClicked()}>{str}</Button>
        }
    }

    renderPrintAllQRButton() {
        for (const doc of this.props.recordToView.docs) {
            if (doc.status !== 'Received') {
                return
            } 
        }
        return <PrintAllComponent clientID={this.props.clientID} recordID={this.props.recordToView.id} docs={this.props.recordToView.docs} />
    }

    renderRefreshMessage() {
        if (this.state.showRefreshingStatus) return <Container><Message info content='Refreshing all document status...'/><br /></Container>
        if (this.state.showUpdatingDocStatus) return <Container><Message info content='Updating document status...'/><br /></Container>
        if (this.state.showSaveSuccess) return <Container><Message success content='Changes successfully saved.'/><br /></Container>
        if (this.state.showDeleteSuccess) return <Container><Message success content='Document successfully deleted.'/><br /></Container>
    }

    renderSignatureQRCode() {
        for (const doc of this.props.recordToView.docs) {
            if (doc.status === 'Ready to Return') {
                let url = "https://theaccountants.sg/client_e-signature/client/" + this.props.clientID + "/record/" + this.props.recordToView.id
                console.log(url)
                return <div> 
                    <h3 style={{ marginTop: 30, marginBottom: 25 }}>Scan this QR Code with your mobile device to obtain an e-Signature from the client:</h3>
                    <QRCode style={{ marginBottom: 20 }} size={100} value={url} />
                </div>
            }
        }
    }

    renderSignatures() {
        var arr = []
        console.log(this.props.recordToView)
        if (!!this.props.recordToView.signatures) {
            for (const signature of this.props.recordToView.signatures) {
                var items = []
                for (const item of signature.items) {
                    items.push(item.type)
                }
                arr.push(
                    <Card style={{ marginRight: 15 }}>
                        <Image src={signature.signature} wrapped ui={false} />
                        <CardContent>
                        <CardHeader>{signature.name}</CardHeader>
                        <CardMeta><span className='date'>Signed at {format(signature.date, "d MMMM, yyyy 'at' hh:mm a")}</span></CardMeta>
                        <CardDescription>
                            Items returned: {items.join(", ")}
                        </CardDescription>
                        </CardContent>
                        <CardContent extra>
                        Handled by: {signature.user}
                        </CardContent>
                    </Card>
                )
            }
        }
        return (
            <div style={{ marginTop: 50, marginBottom: 50 }}>
                <Divider style={{ marginBottom: 30 }} horizontal><Header as='h4'><Icon name='pencil' />Signatures</Header></Divider>
                <CardGroup>{arr}</CardGroup>
            </div>
        )
    }

    render() {
        return (
            <Container>
                <Button basic color='black' onClick={this.props.onBack}>Back to Records</Button>
                <h3 style={{marginBottom: 35}}>Document Tracking Record created by {this.props.recordToView.user}, on {format(this.props.recordToView.date, "d MMMM yyyy 'at' hh:mm a")}
                   <Popup content="Refresh the Status of Documents" trigger={<Button style={{ marginLeft: 16 }} basic icon='refresh' onClick={this.handleRefreshClicked}></Button>} position="right center" />
                    </h3>
                {this.renderRefreshMessage()}
                <Divider horizontal><Header as='h4'><Icon name='file alternate outline' />Documents</Header></Divider>
                {this.renderTable()}
                {this.renderAddDocumentButton()}
                {this.renderPrintAllQRButton()}
                {this.renderSignatures()}
                {this.renderSignatureQRCode()}
                {this.renderActionButtons()}
                <Button basic onClick={this.props.onBack}>Back to Records</Button>
                <Confirm open={this.state.showDocHandoverConfirmationPopup} onCancel={this.handleDocHandoverCancel} onConfirm={this.handleDocHandoverConfirm} 
                header="Confirmation" content="Confirm that you have handed over all documents. This will change the status of all documents from 'Received' to 'In Processing'." cancelButton="Cancel" confirmButton="Confirm"/>
                <Confirm open={this.state.showDocReceivedConfirmationPopup} onCancel={this.handleDocReceivedCancel} onConfirm={this.handleDocReceivedConfirm} 
                header="Confirmation" content="Confirm that you have received all documents. This will change the status of all documents from 'In Processing' to 'Ready to Return'." cancelButton="Cancel" confirmButton="Confirm"/>
            </Container>
        )
    }
    
    handleRefreshClicked() {
        this.setState({ showRefreshingStatus: true })
        this.props.getClientDocsFromID(this.props.clientID, (success, data) => {
            if (success) {
                this.props.updateClientDocs(data, this.props.recordToView.id)
                this.setState({ showRefreshingStatus: false })
            } else {
                alert('Failure refreshing document status, please try again')
            }
        })
    }

    handleEditClicked(i, doc) {
        this.setState({ isEditing: true, editingIndex: i, type: doc.type, description: doc.description, remarks: doc.remarks })
    }

    handleDoneEditing(i, doc) {
        const { type, description, remarks } = this.state
        this.setState({ isEditing: false, editingIndex: 0 })
        this.setState({ showUpdatingDocStatus: true })
        let dict = { type, description, remarks }
        this.props.editDocument(this.props.clientID, this.props.recordToView.id, doc.id, dict, (success, data) => {
            if (success) {
                this.props.recordToView.docs[i] = data
                this.setState({ showUpdatingDocStatus: false, showSaveSuccess: true })
            } else {
                alert('Failure editing document, please try again')
            }
        })
    }

    handleDeleteDocClicked = (i, doc) => {
        if (this.state.showDeleteConfirmationPopup) return
        const contentString = "Delete document '" + (i+1) + ". " + doc.type + "'?"
        this.setState({
            idToDelete: doc.id,
            indexToDelete: i,
            deleteConfirmContentString: contentString,
            showDeleteConfirmationPopup: true
        })
    }

    handleDeleteDocCancel = () =>
        this.setState({ showDeleteConfirmationPopup: false })

    handleDeleteDocConfirm = () => {
        this.props.deleteDocument(this.props.clientID, this.props.recordToView.id, this.state.idToDelete, (success) => {
            if (success) {
                this.props.recordToView.docs.splice(this.state.indexToDelete, 1)
                this.setState({ showDeleteConfirmationPopup: false, showDeleteSuccess: true })
            } else {
                alert('Failure deleting document, please try again')
            }
        })
    }

    handleAddNewDocumentClicked = () => {
        if (this.state.addingNewDoc) {
            this.setState({ addingNewDoc: false })
            const { newType, newDescription, newRemarks } = this.state
            const dict = { type: newType, description: newDescription, remarks: newRemarks, status: "Received", auditTrail: [{ status: 'Received', date: Date(), user: this.props.user.name }], id: Date.now() + Math.random() }
            this.props.addDocument(this.props.clientID, this.props.recordToView.id, dict, (success) => {
                if (success) {
                    this.props.recordToView.docs.push(dict)
                    this.setState({ newType: "", newDescription: "", newRemarks: "" })
                } else {
                    alert('Failure adding new document, please try again')
                }
            })
        } else {
            this.setState({ addingNewDoc: true })
        }
    }

    handleDocHandoverClicked() {
        if (this.state.showHandoverConfirmationPopup) return
        this.setState({ showDocHandoverConfirmationPopup: true })
    }

    handleDocReceivedClicked() {
        if (this.state.showDocReceivedConfirmationPopup) return
        this.setState({ showDocReceivedConfirmationPopup: true })
    }

    handleDocHandoverCancel = () => {
        this.setState({ showDocHandoverConfirmationPopup: false })
    }

    handleDocHandoverConfirm = () => {
        this.updateServerDocStatusForAllDocs('In Processing')
        this.setState({ showDocHandoverConfirmationPopup: false })
    }

    handleDocReceivedCancel = () => {
        this.setState({ showDocReceivedConfirmationPopup: false })
    }

    handleDocReceivedConfirm = () => {
        this.updateServerDocStatusForAllDocs('Ready to Return')
        this.setState({ showDocReceivedConfirmationPopup: false })
    }

    handleGenerateESignature = () => {
        alert('Generate e-Signature for Client: Work in Progress')
    }

    handleAuditTrailShowDetailsClicked = () => {
        this.setState({ showAuditTrailDetails: !this.state.showAuditTrailDetails })
    }

    updateServerDocStatusForAllDocs(status) {
        this.setState({ showUpdatingDocStatus: true })
        var docIDs = []
        for (const doc of this.props.recordToView.docs) {
            docIDs.push(doc.id)
        }
        let dict = { recordID: this.props.recordToView.id, docIDs, status: status, user: this.props.user.name }
        this.props.updateDocumentStatus(this.props.clientID, dict, (success, data) => {
            if (success) {
                // fetch client docs from server
                this.props.updateClientDocs(data, this.props.recordToView.id)
            } else {
                alert('Failure changing document status, please try again')
            }
        })
    }
}

function mapStateToProps({ client, clientDocs, user }) {
    return { client, clientDocs, user }
}

export default connect(mapStateToProps, actions)(ClientViewDoc)

