import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { Container, Button, Input, Transition, Icon } from 'semantic-ui-react'

class DocScanQRLanding extends Component {
    state = { showDocHandoverConfirmationPopup: false, showDocReceivedConfirmationPopup: false, visible: true, initials: "", disableDoneButton: false, buttonLoading: false, doneScan: false }

    constructor(props) {
        super(props)
        this.handleDoneClicked = this.handleDoneClicked.bind(this)
    }

    componentDidMount() {
        this.props.getDocumentFromID(this.props.match.params.clientID, this.props.match.params.recordID, this.props.match.params.docID)
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value})
    }

    renderDoneButton() {
        if (this.state.doneScan) {
            return <Button style={{ marginTop: 20 }} color="green">Scan Successful<Icon style={{ marginLeft: 10, marginRight: 10}} name="check" /></Button>
        } else {
            return (
                <Transition animation='shake' duration={150} visible={this.state.visible}>
                    <Button style={{ marginTop: 20 }} color="red" disabled={this.state.disableDoneButton} loading={this.state.buttonLoading} onClick={this.handleDoneClicked}>Done</Button>
                </Transition>
            )
        }
    }

    render() {
        var str = ""
        var desc = ""
        if (!!this.props.clientDoc) {
            console.log(this.props.clientDoc.data.doc.status)
            if (this.props.clientDoc.data.doc.status === 'Ready to Return') {
                return (
                    <Container style={{ marginTop: 50 }}>
                        <h2>This document has already been scanned.</h2>
                    </Container>
                )
            } else if (this.props.clientDoc.data.doc.status === 'Returned') {
                return (
                    <Container style={{ marginTop: 50 }}>
                        <h2>This document has already been returned.</h2>
                    </Container>
                )
            }
            if (this.props.clientDoc.data.doc.description !== "") desc = ' (' + this.props.clientDoc.data.doc.description + ')'
            str = this.props.clientDoc.data.doc.type + desc + ' – ' + this.props.clientDoc.data.clientName
        } else {
            return (
                <Container style={{ marginTop: 50 }}>
                    <h2>Retrieving Document...</h2>
                </Container>
            )
        }
        return (
            <Container style={{ marginTop: 50 }}>
                <h3>Scanning document: {str}</h3>
                <h3>Please enter your initials below to confirm</h3>
                <Input name='initials' value={this.state.initials} placeholder='Your initials here' onChange={this.handleChangeField}></Input>
                <br />
                {this.renderDoneButton()}
            </Container>
        )
    }

    handleDoneClicked() {
        const { initials } = this.state
        if (initials === "") {
            this.setState((prevState) => ({ visible: !prevState.visible }))
            return
        }
        this.setState({ disableDoneButton: true, buttonLoading: true })
        var arr = []
        arr.push(this.props.clientDoc.data.doc.id)
        let dict = { recordID: this.props.match.params.recordID, docIDs: arr, status: 'Ready to Return', initials }
        this.props.updateDocumentStatus(this.props.match.params.clientID, dict, (success, data) => {
            if (success) {
                this.setState({ disableDoneButton: false, buttonLoading: false, doneScan: true })
                alert('Scanning successful – document status changed to Ready to Return. You may now close this page.')
            } else {
                this.setState({ disableDoneButton: false, buttonLoading: false })
                alert('Failure changing document status, please try again')
            }
        })
    }
}

function mapStateToProps({ clientDoc }) {
    return { clientDoc }
}

export default connect(mapStateToProps, actions)(DocScanQRLanding)

