import React, { Component } from "react"
import { fields } from "../../../../fields"

import { Container, Form, FormGroup, FormField, Input, Radio, TextArea, Checkbox } from 'semantic-ui-react'

const departmentName = 'Corp Sec'

class CorpSec extends Component {
    state = {}

    componentDidMount() {
        var savedDict = {}
        if (this.props.savedDict) {
            savedDict = this.props.savedDict
        } else {
            return
        }
        this.setState({
            typeOfEngagement: savedDict.typeOfEngagement,
            group: savedDict.group,
            secFileNumber: savedDict.secFileNumber,
            dateAcraStrikeOff: savedDict.dateAcraStrikeOff,
            noStrikeOffDate: savedDict.noStrikeOffDate,
            FYE: savedDict.FYE,
            lastAccountFiled: savedDict.lastAccountFiled,
            agreementNo: savedDict.agreementNo,
            dateOfAgreement: savedDict.dateOfAgreement,
            monthOfAgreement: savedDict.monthOfAgreement,
            remarksForMonthOfAgreement: savedDict.remarksForMonthOfAgreement,
            commonSeal: savedDict.commonSeal,
            nameOfSecretary: savedDict.nameOfSecretary,
            nameOfSecretary2: savedDict.nameOfSecretary2,
            dateApptAsSec: savedDict.dateApptAsSec,
            dateApptAsSec2: savedDict.dateApptAsSec2,
            dateResignAsSec: savedDict.dateResignAsSec,
            dateResignAsSec2: savedDict.dateResignAsSec2,
            remarksForApptResignAsSec: savedDict.remarksForApptResignAsSec,
            howManyShareholderAgreement: savedDict.howManyShareholderAgreement,
            remarks: savedDict.remarks,
            others: savedDict.others,
        })
    }

    handleChangeField = e => {
        if (this.props.viewOnly) {
            if (this.state[e.target.name] == null) {
                this.setState({ [e.target.name]: ""})
            }
            return
        }
        const { name, value } = e.target
        if (!getWritePermissionForField(name, this.props.permissions) && this.state[e.target.name] == null) {
            this.setState({ [e.target.name]: ""})
            return
        }   
        else if (!getWritePermissionForField(name, this.props.permissions)) return
        this.setState({ [name]: value }, () =>
            this.props.onStateChange(this.state)
        )
    }
    handleChangeAdHoc = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('adHoc', this.props.permissions)) return
        this.setState({ adHoc: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeFYE = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('FYE', this.props.permissions)) return
        this.setState({ FYE: value }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeMonthOfAgreement = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('monthOfAgreement', this.props.permissions)) return
        console.log(value)
        var arr = [...this.state.monthOfAgreement ?? []]
        console.log(arr)
        if (arr.includes(value)) {
            let index = arr.indexOf(value)
            arr.splice(index, 1)
        } else {
            arr.push(value)
        }
        this.setState({ monthOfAgreement: arr }, () => 
            this.props.onStateChange(this.state)
        )
    }
    handleChangeCommonSeal = (e, { value }) => { 
        if (this.props.viewOnly) return
        if (!getWritePermissionForField('commonSeal', this.props.permissions)) return
        this.setState({ commonSeal: value }, () => 
            this.props.onStateChange(this.state)
        )
    }

    getFieldStyle(name) {
        if (!getReadPermissionForField(name, this.props.permissions)) {
            return { visibility: 'hidden' }
        }
        return { visibility: 'visible' }
    }

    render() {
        const { group, typeOfEngagement, secFileNumber, dateAcraStrikeOff, noStrikeOffDate, FYE, lastAccountFiled, agreementNo, dateOfAgreement, monthOfAgreement, remarksForMonthOfAgreement, commonSeal, 
            nameOfSecretary, nameOfSecretary2, dateApptAsSec, dateApptAsSec2, dateResignAsSec, dateResignAsSec2, remarksForApptResignAsSec, howManyShareholderAgreement, remarks, others } = this.state
        return (
            <Container>
                <Form>
                    <FormField style={this.getFieldStyle('typeOfEngagement')} inline control={Input} label='Type of Engagement' name='typeOfEngagement' value={typeOfEngagement} onChange={this.handleChangeField}/>
                    <FormGroup widths={"equal"}>
                    <FormField style={this.getFieldStyle('secFileNumber')} control={Input} label='Sec File Number' name='secFileNumber' value={secFileNumber} placeholder='Sec File Number' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('group')} control={Input} label='Group' name='group' value={group} placeholder='Group' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('dateAcraStrikeOff')} control={Input} type='date' label='Date ACRA Filing for Strike Off' name='dateAcraStrikeOff' value={dateAcraStrikeOff} placeholder='Select date' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <br />
                    <FormGroup style={this.getFieldStyle('noStrikeOffDate')} inline>
                        <label>No Strike Off Date:</label>
                        <FormField control={Radio} label='Yes' value='yes' checked={noStrikeOffDate === 'yes'} onChange={this.handleChangeAdHoc}/>
                        <FormField control={Radio} label='No' value='no' checked={noStrikeOffDate === 'no'} onChange={this.handleChangeAdHoc}/>
                    </FormGroup>
                    <br />
                    <FormGroup style={this.getFieldStyle('FYE')} inline>
                        <label>FYE:</label>
                        <FormField control={Radio} label='Jan' value='JAN' checked={FYE === 'JAN'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Feb' value='FEB' checked={FYE === 'FEB'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Mar' value='MAR' checked={FYE === 'MAR'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Apr' value='APR' checked={FYE === 'APR'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='May' value='MAY' checked={FYE === 'MAY'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Jun' value='JUN' checked={FYE === 'JUN'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Jul' value='JUL' checked={FYE === 'JUL'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Aug' value='AUG' checked={FYE === 'AUG'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Sep' value='SEP' checked={FYE === 'SEP'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Oct' value='OCT' checked={FYE === 'OCT'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Nov' value='NOV' checked={FYE === 'NOV'} onChange={this.handleChangeFYE}/>
                        <FormField control={Radio} label='Dec' value='DEC' checked={FYE === 'DEC'} onChange={this.handleChangeFYE}/>
                    </FormGroup>
                    <br />
                    <FormField style={this.getFieldStyle('lastAccountFiled')} inline control={Input} type='date' label='Last Account Filed' name='lastAccountFiled' value={lastAccountFiled} placeholder='Select date' onChange={this.handleChangeField}/>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('agreementNo')} control={Input} label='Agreement No' name='agreementNo' value={agreementNo} placeholder='Agreement Number' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('dateOfAgreement')} control={Input} type='date' label='Date of Agreement' name='dateOfAgreement' value={dateOfAgreement} placeholder='Select date' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <br />
                    <FormGroup style={this.getFieldStyle('monthOfAgreement')} inline>
                        <label>Different Month to Issue Invoice:</label>
                        <FormField control={Checkbox} label='Jan' value='1' checked={(monthOfAgreement ?? []).includes('1')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Feb' value='2' checked={(monthOfAgreement ?? []).includes('2')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Mar' value='3' checked={(monthOfAgreement ?? []).includes('3')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Apr' value='4' checked={(monthOfAgreement ?? []).includes('4')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='May' value='5' checked={(monthOfAgreement ?? []).includes('5')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Jun' value='6' checked={(monthOfAgreement ?? []).includes('6')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Jul' value='7' checked={(monthOfAgreement ?? []).includes('7')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Aug' value='8' checked={(monthOfAgreement ?? []).includes('8')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Sep' value='9' checked={(monthOfAgreement ?? []).includes('9')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Oct' value='10' checked={(monthOfAgreement ?? []).includes('10')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Nov' value='11' checked={(monthOfAgreement ?? []).includes('11')} onChange={this.handleChangeMonthOfAgreement}/>
                        <FormField control={Checkbox} label='Dec' value='12' checked={(monthOfAgreement ?? []).includes('12')} onChange={this.handleChangeMonthOfAgreement}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('remarksForMonthOfAgreement')} control={TextArea} rows='2' label='Remarks for Month of Agreement' name='remarksForMonthOfAgreement' value={remarksForMonthOfAgreement} placeholder='' onChange={this.handleChangeField}/>
                    <br />
                    <FormGroup style={this.getFieldStyle('commonSeal')} inline>
                        <label>Common Seal:</label>
                        <FormField control={Radio} label='Yes' value='Yes' checked={commonSeal === 'Yes'} onChange={this.handleChangeCommonSeal}/>
                        <FormField control={Radio} label='No' value='No' checked={commonSeal === 'No'} onChange={this.handleChangeCommonSeal}/>
                    </FormGroup>
                    <br />
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('nameOfSecretary')} control={Input} label='Name of Secretary (1)' name='nameOfSecretary' value={nameOfSecretary} placeholder='Name of Secretary (1)' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('dateApptAsSec')} control={Input} type='date' label='Date of Appointment as Secretary (1)' name='dateApptAsSec' value={dateApptAsSec} placeholder='Select date' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('dateResignAsSec')} control={Input} type='date' label='Date of Resignation as Secretary (1)' name='dateResignAsSec' value={dateResignAsSec} placeholder='Select date' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormGroup widths={"equal"}>
                        <FormField style={this.getFieldStyle('nameOfSecretary2')} control={Input} label='Name of Secretary (2)' name='nameOfSecretary2' value={nameOfSecretary2} placeholder='Name of Secretary (2)' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('dateApptAsSec2')} control={Input} type='date' label='Date of Appointment as Secretary (2)' name='dateApptAsSec2' value={dateApptAsSec2} placeholder='Select date' onChange={this.handleChangeField}/>
                        <FormField style={this.getFieldStyle('dateResignAsSec2')} control={Input} type='date' label='Date of Resignation as Secretary (2)' name='dateResignAsSec2' value={dateResignAsSec2} placeholder='Select date' onChange={this.handleChangeField}/>
                    </FormGroup>
                    <FormField style={this.getFieldStyle('remarksForApptResignAsSec')} control={TextArea} rows='4' label='Remarks for Appointment / Resignation as Secretary' name='remarksForApptResignAsSec' value={remarksForApptResignAsSec} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('howManyShareholderAgreement')} inline control={Input} label='How many shareholder agreeement' name='howManyShareholderAgreement' value={howManyShareholderAgreement} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('remarks')} control={TextArea} rows='2' label='Remarks' name='remarks' value={remarks} placeholder='' onChange={this.handleChangeField}/>
                    <FormField style={this.getFieldStyle('others')} control={Input} label='Others' name='others' value={others} placeholder='' onChange={this.handleChangeField}/>
                </Form>
            </Container>
        )
    }
}

function getWritePermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            if (permission.write === false) {
            }
            return permission.write
        }
    }
}

function getReadPermissionForField(fieldName, permissions) {
    var key = ""
    for (let field of fields) {
        if (field.field === fieldName && field.departmentName === departmentName) {
            key = field.key
        }
    }
    for (let permission of permissions) {
        if (permission.key === key) {
            return permission.read
        }
    }
}

export default CorpSec