import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { Container, Divider, Header, Icon, Button, Form, FormField, Select, Input, TextArea, Table, TableHeader, TableRow, TableCell, TableHeaderCell, TableBody, Transition } from 'semantic-ui-react'

const options = [
    { key: 'audit-working-paper', text: 'Audit Working Paper', value: 'Audit Working Paper' },
    { key: 'arch-file', text: 'Arch File', value: 'Arch File' },
    { key: 'paper-file', text: 'Paper File', value: 'Paper File' },
    { key: 'envelope', text: 'Envelope', value: 'Envelope' },
    { key: 'cheque-book', text: 'Cheque Book', value: 'Cheque Book' },
    { key: 'cheque-counter-foil', text: 'Cheque Counter Foil', value: 'Cheque Counter Foil' },
    { key: 'clear-file', text: 'Clear File', value: 'Clear File' },
    { key: 'bundle-documents', text: 'Bundle Documents', value: 'Bundle Documents' },
    { key: 'binding-report', text: 'Binding Report', value: 'Binding Report' },
    { key: 'accounting-report', text: 'Accounting Report', value: 'Accounting Report' },
    { key: 'signing-documents', text: 'Signing Documents', value: 'Signing Documents' },
    { key: 'others', text: 'Others', value: 'Others' },
  ]

class ClientDoc extends Component {
    state = { docs: [], type: "", quantity: "1", description: "", remarks: "", visible: true, visible2: true }

    componentDidMount() {
        
    }

    handleChangeField = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    handleChangeTypeDropDown = e => {
        this.setState({ type: e.target.innerText })
    }

    handleAddDocument() {
        const { type, quantity, description, remarks } = this.state
        if (type === "") {
            this.setState((prevState) => ({ visible: !prevState.visible }))
            return
        }
        var arr = this.state.docs
        for (let i = 0; i < quantity; i++) {
            const dict = { type, description, remarks, status: "Received", auditTrail: [{ status: 'Received', date: Date(), user: this.props.user.name }], id: Date.now() + Math.random() }
            arr.push(dict)
        }
        this.setState( { type: "", description: "", remarks: "", docs: arr })
    }

    handleDeleteClicked(doc) {
        for (var i = 0; i < this.state.docs.length; i++) {
            if (doc === this.state.docs[i]) {
                var arr = this.state.docs
                arr.splice(i, 1)
                this.setState({ doc: arr })
                break
            }
        }
    }

    handleSave() {
        if (this.state.docs.length === 0) {
            this.setState((prevState) => ({ visible2: !prevState.visible2 }))
            return
        }
        const dict = { id: Date.now() + Math.random(), docs: this.state.docs, date: Date(), user: this.props.user.name, remarks: this.state.remarksRecord }
        // update server to save new record
        this.props.addDocumentRecordToClient(this.props.clientID, dict, (success) => {
            if (success) {
                this.props.onSave(dict)
            } else {
                alert('Failure adding document record, please try again')
            }
        })
    }

    renderTable() {
        if (this.state.docs.length === 0) {
            return <h3>No documents to show</h3>
        }
        return (
            <Table celled striped compact>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>No</TableHeaderCell>
                        <TableHeaderCell width='3'>Type</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell width='4'>Remarks</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                { this.state.docs.map((doc, i) =>
                    <TableRow key={i}>
                        <TableCell collapsing>{i+1}</TableCell>
                        <TableCell>{doc.type}</TableCell>
                        <TableCell>{doc.description}</TableCell>
                        <TableCell>{doc.remarks}</TableCell>
                        <TableCell collapsing><Button circular basic icon='trash alternate' color='red' onClick={this.handleDeleteClicked.bind(this, doc)}/></TableCell>
                    </TableRow> )}
                </TableBody>
            </Table>
        )
    }

    renderSaveButton(basic2, color2) {
        if (this.state.docs.length > 0) return <Button basic={basic2} style={{ marginRight: 15 }} color={color2} onClick={() => this.handleSave()}>No more documents to add<Icon style={{ marginLeft: 10 }} name="check" /></Button>
    }

    render() {
        const { type, description, quantity, remarks, remarksRecord } = this.state
        var basic1 = false
        var color1 = 'red'
        var basic2 = true
        var color2 = 'black'
        if (type === "") {
            basic1 = true
            color1 = 'black'
            basic2 = false
            color2 = 'red'
        }
        return (
            <Container>
                <Divider horizontal><Header as='h4'><Icon name='file outline' />New Document</Header></Divider>
                <Form>
                    <FormField control={Select} required label='Document Type' name='type' value={type} defaultValue={type} options={options} placeholder='Select one' onChange={this.handleChangeTypeDropDown}/>
                    <FormField control={Input} required width={1} label='Quantity' name='quantity' value={quantity} onChange={this.handleChangeField} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() }}}/>
                    <FormField control={TextArea} rows='1' label='Description' name='description' value={description} placeholder='' onChange={this.handleChangeField}/>
                    <FormField control={Input} label='Remarks' name='remarks' value={remarks} onChange={this.handleChangeField}/>
                </Form>
                <br />
                <Transition animation='shake' duration={150} visible={this.state.visible}>
                    <Button basic={basic1} style={{ marginRight: 15 }} color={color1} onClick={() => this.handleAddDocument()}>Add Document</Button>
                </Transition>
                <br /><br />
                <Divider horizontal><Header as='h4'><Icon name='file alternate outline' />Added documents</Header></Divider>
                {this.renderTable()}
                <br />
                <Form>
                <FormField control={Input} required label='Remarks for this record' name='remarksRecord' placeholder='(E.g. for client FYE 2024)' value={remarksRecord} onChange={this.handleChangeField}/>
                </Form>
                <br /><br />
                {this.renderSaveButton(basic2, color2)}
                <Button onClick={this.props.onCancel} basic>Cancel and Go Back</Button>
            </Container>
        )
    }
}

function mapStateToProps({ client, user }) {
    return { client, user }
}

export default connect(mapStateToProps, actions)(ClientDoc)