import React from "react"
import { useReactToPrint } from "react-to-print"
import QRCode from "react-qr-code"

import { Button, Popup } from 'semantic-ui-react'

export const PrintComponent = (props) => {
    const componentRef = React.useRef(null)
    const handleAfterPrint = React.useCallback(() => { console.log("`onAfterPrint` called") }, [])
    const handleBeforePrint = React.useCallback(() => {
      console.log("`onBeforePrint` called")
      return Promise.resolve()
    }, [])
    const fileName = props.displayStr + ". " + props.doc.type
    const printFn = useReactToPrint({ contentRef: componentRef, documentTitle: fileName, onAfterPrint: handleAfterPrint, onBeforePrint: handleBeforePrint })
    const url = "https://theaccountants.sg/scan_document/client/" + props.clientID + "/record/" + props.recordID + "/document/" + props.doc.id
  
    console.log(url)
    console.log(props.doc)
    return (
        <div>
            <Popup content="Print QR Code only for this document" trigger={<Button circular basic icon='print' onClick={printFn} />} position="left center" />
                <div style={{ display: "none" }}>
                <div ref={componentRef} >
                    <h1>{props.displayStr}</h1><QRCode size={180} value={url} />
                </div>
            </div>
        </div>
    )
}

export const PrintAllComponent = (props) => {
    const componentRef = React.useRef(null)
    const handleAfterPrint = React.useCallback(() => { console.log("`onAfterPrint` called") }, [])
    const handleBeforePrint = React.useCallback(() => {
      console.log("`onBeforePrint` called")
      return Promise.resolve()
    }, [])
    const printFn = useReactToPrint({ contentRef: componentRef, documentTitle: "Bubbly QR Codes", onAfterPrint: handleAfterPrint, onBeforePrint: handleBeforePrint })
  
    const renderQRCodes = () => {
        var rows = []
        if (!!props.docs) {
            for (const [i, doc] of props.docs.entries()) {
                const url = "https://theaccountants.sg/scan_document/client/" + props.clientID + "/record/" + props.recordID + "/document/" + doc.id
                rows.push(
                    <div>
                        <h1>{i+1 + ". " + doc.type}</h1><QRCode size={180} value={url} />
                        <div style={{ pageBreakBefore: 'always' }} />
                    </div>
                )
                if (i+1 < props.docs.length) {
                    rows.push(<div style={{ pageBreakBefore: 'always' }} />)
                }
            }
        }
        return rows
    }

    return (
        <div>
            <Button basic color="black" style={{ marginTop: 10 }} onClick={printFn}>Print All QR Codes</Button>
                <div style={{ display: "none" }}>
                <div ref={componentRef} >
                    {renderQRCodes()}
                </div>
            </div>
        </div>
    )
}
