import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../actions"

import { Container, Button, Input, Transition, Icon} from 'semantic-ui-react'

import SignatureCanvas from 'react-signature-canvas'

class SignaturePad extends Component {
    state = { showDocHandoverConfirmationPopup: false, showDocReceivedConfirmationPopup: false, visible: true, initials: "", representativeName: "", disableDoneButton: false, buttonLoading: false }
    sigPad = {}

    constructor(props) {
        super(props)
        this.handleDoneClicked = this.handleDoneClicked.bind(this)
        this.handleClearClicked = this.handleClearClicked.bind(this)
    }

    componentDidMount() {
        this.props.getClientDocRecordFromID(this.props.match.params.clientID, this.props.match.params.recordID)
    }

    handleChangeField = e => {
        const { value } = e.target
        this.setState({ [e.target.name]: value})
    }

    renderPartialReturn(unreadyItems) {
        if (unreadyItems.length > 0) {
            let str1 = "Please note that the following items are still in processing, and will be returned on a future date: "
            let str2 = unreadyItems.join(", ")
            return <h3><Icon name='exclamation' />{str1}<br /><div style={{ marginTop: 15, marginLeft: 25, marginBottom: 36 }}>{str2}</div></h3>
        } 
    }

    render() {
        var companyName = ""
        var readyItems = []
        var unreadyItems = []
        if (!!this.props.clientRecord) {
            if (!!this.props.clientRecord.data) {
                companyName = this.props.clientRecord.data.companyName
                for (const doc of this.props.clientRecord.data.record.docs) {
                    if (doc.status === "Ready to Return") readyItems.push(doc.type)
                    if (doc.status === "In Processing") unreadyItems.push(doc.type)
                }
            }
        }
        let str1 = "Obtain e-signature from the representative of '" + companyName + "':"
        let str2 = "Please acknowledge receipt of the following items: " + readyItems.join(", ")
        return (
            <Container style={{ marginTop: 50 }}>
                <h3><Icon name='file alternate outline' />{str1}</h3>
                <h3><Icon name='check' />{str2}</h3>
                {this.renderPartialReturn(unreadyItems)}
                <h3>Please enter your name here:</h3>
                <Input name='representativeName' value={this.state.representativeName} placeholder='Your name here' onChange={this.handleChangeField}></Input>
                <br />
                <h3>Please sign below:</h3>
                <SignatureCanvas backgroundColor="lightGray" penColor='black' canvasProps={{width: 500, height: 200 }} ref={(ref) => { this.sigPad = ref }} />
                <br />
                <Button style={{ marginTop: 10, marginBottom: 15 }} basic onClick={this.handleClearClicked}>Clear</Button>
                <h3>Please enter initials of FMD representative:</h3>
                <Input name='initials' value={this.state.initials} placeholder='Your initials here' onChange={this.handleChangeField}></Input>
                <br />
                <Transition animation='shake' duration={150} visible={this.state.visible}>
                    <Button disabled={this.state.disableDoneButton} loading={this.state.buttonLoading} style={{ marginTop: 30, marginRight: 20 }} color="red" onClick={this.handleDoneClicked}>Save Signature</Button>
                </Transition>
            </Container>
        )
    }

    handleClearClicked() {
        this.sigPad.clear()
    }

    handleDoneClicked() {
        let { representativeName, initials } = this.state
        if (representativeName === "" || initials === "") {
            this.setState((prevState) => ({ visible: !prevState.visible }))
            return
        }
        this.setState({trimmedDataURL: this.sigPad.toDataURL(), disableDoneButton: true, buttonLoading: true})
        var items = []
        for (const doc of this.props.clientRecord.data.record.docs) {
            if (doc.status === "Ready to Return") items.push(doc)
        }
        let dict = { representativeName, signature: this.sigPad.toDataURL(), initials, items }
        this.props.uploadClientSignature(this.props.match.params.clientID, this.props.match.params.recordID, dict, (success) => {
            if (success) {
                var docIDs = []
                for (const item of items) {
                    docIDs.push(item.id)
                }
                let dict2 = { recordID: this.props.match.params.recordID, docIDs, status: 'Returned', initials }
                this.props.updateDocumentStatus(this.props.match.params.clientID, dict2, (success, data) => {
                    if (success) {
                        this.setState({ disableDoneButton: false, buttonLoading: false })
                        alert('Signature save successful. You may now close this page.')
                    } else {
                        this.setState({ disableDoneButton: false, buttonLoading: false })
                        alert('Signature save successful, but failure changing document status to "Returned". Please try again')
                    }
                })
            } else {
                this.setState({ disableDoneButton: false, buttonLoading: false })
                alert('Failure saving signature, please try again')
            }
        })
    }
}

function mapStateToProps({ clientRecord }) {
    return { clientRecord }
}

export default connect(mapStateToProps, actions)(SignaturePad)

