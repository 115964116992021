import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../../actions"

import ClientInfo from "./client-forms/ClientInfo"
import ClientDocs from "./client-forms/ClientDocs"
import Audit from "./client-forms/Audit"
import BusinessDev from "./client-forms/BusinessDev"
import Operations from "./client-forms/Operations"
import CorpSec from "./client-forms/CorpSec"
import OutsourcedAccounting from "./client-forms/OutsourcedAccounting"
import TaxFS from "./client-forms/TaxFS"
import AuditTrail from "./AuditTrail"

import { Container, Breadcrumb, BreadcrumbSection, BreadcrumbDivider, Menu, MenuItem, MenuMenu, Icon, Button, ButtonContent, Message } from 'semantic-ui-react'

import { Packer } from "docx"
import { saveAs } from "file-saver"
import { doc } from '../../../ClientInformationDocument'

const buttonStyle = {
    marginRight: "15px",
}

const downloadButtonStyle = {
    width: "100px",
}

// Dicts here retain data after user navigates from one child component to another.
var clientInfoDict = { remarksInfo: "" }
var clientDocsDict = { }
var auditDict = { remarks: "" }
var businessDevDict = { remarks: "" }
var operationsDict = { remarks: "" }
var corpSecDict = { remarks: "" }
var outsourcedAccountingDict = { remarks: "" }
var taxFSDict = { remarks: "" }
var pushedAuditTrail = false

class NewClient extends Component {
    state = { activeItem: '', keyForClientDocs: 0, docsRedirect: '' }
  
    constructor(props) {
        super(props)
        this.resetDicts()
        this.handleMenuItemClicked = this.handleMenuItemClicked.bind(this)
        this.handleSaveClicked = this.handleSaveClicked.bind(this)
        this.handleCancelClicked = this.handleCancelClicked.bind(this)
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this)
        this.handleUpdateClientDocs = this.handleUpdateClientDocs.bind(this)
        this.handleResetDocsRedirect = this.handleResetDocsRedirect.bind(this)
    }

    componentDidMount() {
        var showClientInfo, showAudit, showBusinessDev, showOperations, showCorpSec, showAccounting, showTax, showAuditTrail = false
        if (!!this.props.user && this.props.isEditing) {
            if (!!this.props.user.role) {
                if (!!this.props.user.name) {
                    if (this.props.user.role.name.toLowerCase().includes("superadmin")) {
                        showAuditTrail = true
                    } 
                }
            }
        }
        if (this.props.isEditing) {
            clientInfoDict = this.props.clientToEdit.clientInfo
            clientDocsDict = this.props.clientToEdit.clientDocs
            auditDict = this.props.clientToEdit.audit
            businessDevDict = this.props.clientToEdit.businessDev
            operationsDict = this.props.clientToEdit.operations
            corpSecDict = this.props.clientToEdit.corpSec
            outsourcedAccountingDict = this.props.clientToEdit.outsourcedAccounting
            taxFSDict = this.props.clientToEdit.taxFS
        }
        if (this.props == null) {
            return
        }
        if (!!this.props) {
            for (let permission of this.props.permissions) {
                const dept = permission.key.split('-')[1]
                if (dept === 'ct' && permission.read === true) {
                    showClientInfo = true
                    continue
                } else if (dept === 'au' && permission.read === true) {
                    showAudit = true
                    continue
                } else if (dept === 'bd' && permission.read === true) {
                    showBusinessDev = true
                    continue
                } else if (dept === 'op' && permission.read === true) {
                    showOperations = true
                    continue
                } else if (dept === 'cs' && permission.read === true) {
                    showCorpSec = true
                    continue
                } else if (dept === 'oa' && permission.read === true) {
                    showAccounting = true
                    continue
                } else if (dept === 'tx' && permission.read === true) {
                    showTax = true
                    continue
                }
            }
        }
        this.setState({ showClientInfo, showAudit, showBusinessDev, showOperations, showCorpSec, showAccounting, showTax, showAuditTrail })
        if (showClientInfo) {
            this.setState( { activeItem: 'info' })
        } else if (showAudit) {
            this.setState( { activeItem: 'audit' })
        }
    }
    
    renderContent() {
        const { keyForClientDocs, docsRedirect, recordIDRedirect } = this.state
        let perms = this.props.permissions
        var clientID = ""
        if (this.props.isEditing) clientID = this.props.clientToEdit._id
        switch (this.state.activeItem) {
            case "info":
                return <ClientInfo onStateChange={this.handleClientInfoStateChange} savedDict={clientInfoDict} permissions={getPermissionsForDept('ct', perms)}/>
            case "docs":
                return <ClientDocs updateClientDocs={this.handleUpdateClientDocs} clientID={clientID} savedDict={clientDocsDict} key={keyForClientDocs} redirectTo={docsRedirect} recordIDRedirect={recordIDRedirect} resetDocsRedirect={this.handleResetDocsRedirect}
                 permissions={getPermissionsForDept('cd', perms)}/>
            case "audit":
                return <Audit onStateChange={this.handleAuditStateChange} savedDict={auditDict} permissions={getPermissionsForDept('au', perms)}/>
            case "businessDev":
                return <BusinessDev onStateChange={this.handleBusinessDevStateChange} savedDict={businessDevDict} permissions={getPermissionsForDept('bd', perms)}/>
            case "operations":
                return <Operations onStateChange={this.handleOperationsStateChange} savedDict={operationsDict} permissions={getPermissionsForDept('op', perms)}/>
            case "corpSec":
                return <CorpSec onStateChange={this.handleCorpSecStateChange} savedDict={corpSecDict} permissions={getPermissionsForDept('cs', perms)}/>
            case "outsourcedAccounting":
                return <OutsourcedAccounting onStateChange={this.handleOutsourcedAccountingStateChange} savedDict={outsourcedAccountingDict} permissions={getPermissionsForDept('oa', perms)}/>
            case "taxFS":
                return <TaxFS onStateChange={this.handleTaxFSStateChange} savedDict={taxFSDict} permissions={getPermissionsForDept('tx', perms)}/>
            case "auditTrail":
                return <AuditTrail auditTrail={this.props.clientToEdit.auditTrail}/>
            default:
                return
        }
    }

    renderClientInfo() {
        if (this.state.showClientInfo) return <MenuItem name='Client Info' active={this.state.activeItem === 'info'} onClick={this.handleMenuItemClicked}/>
    }

    renderClientDocs() {
        if (this.props.isEditing) return <MenuItem name='Documents' active={this.state.activeItem === 'docs'} onClick={this.handleMenuItemClicked}/>
    }

    renderAudit() {
        if (this.state.showAudit) return <MenuItem name='Audit' active={this.state.activeItem === 'audit'} onClick={this.handleMenuItemClicked}/>
    }

    renderBusinessDev() {
        if (this.state.showBusinessDev) return <MenuItem name='Business Development' active={this.state.activeItem === 'businessDev'} onClick={this.handleMenuItemClicked}/>
    }

    renderOperations() {
        if (this.state.showOperations) return <MenuItem name='Operations' active={this.state.activeItem === 'operations'} onClick={this.handleMenuItemClicked}/>
    }

    renderCorpSec() {
        if (this.state.showCorpSec) return <MenuItem name='Corp Sec' active={this.state.activeItem === 'corpSec'} onClick={this.handleMenuItemClicked}/>
    }

    renderAccounting() {
        if (this.state.showAccounting) return <MenuItem name='Outsourced Accounting' active={this.state.activeItem === 'outsourcedAccounting'} onClick={this.handleMenuItemClicked}/>
    }

    renderTax() {
        if (this.state.showTax) return <MenuItem name='Tax And FS' active={this.state.activeItem === 'taxFS'} onClick={this.handleMenuItemClicked}/>
    }

    renderAuditTrail() {
        if (this.state.showAuditTrail) return(<MenuItem name='Audit Trail' active={this.state.activeItem === 'auditTrail'} onClick={this.handleMenuItemClicked}/>)
    }

    renderDownload() {
        if (!this.props.isEditing) return
        if (!this.props.canExportClientInfoForm) return
        return (
            <MenuMenu position='right'>
            <MenuItem>
            <Button style={downloadButtonStyle} animated='fade' color='red' onClick={this.handleDownloadClicked}>
                <ButtonContent hidden>Download</ButtonContent>
                <ButtonContent visible>
                    <Icon name='download'/>
                </ButtonContent>
            </Button>
            </MenuItem>
          </MenuMenu>
        )
    }

    renderTopButtonsForClientInfo(string) {
        if (this.state.activeItem === 'info') {
            return (
                <div>
                    <br />
                    <Button style={buttonStyle} color='red' onClick={this.handleSaveClicked}>{string}</Button><Button basic onClick={this.handleCancelClicked}>Cancel and Go Back</Button>
                </div>
            ) 
        }
    }

    renderBottomButtons(saveButtonString) {
        if (this.state.activeItem === 'docs') return
        return (<div>
            <Button style={buttonStyle} color='red' onClick={this.handleSaveClicked}>{saveButtonString}</Button><Button basic onClick={this.handleCancelClicked}>Cancel and Go Back</Button>
        </div>)
    }

    renderSuccessMessage() {
        if (this.state.showEditClientSuccess) {
            return (<Container>
                <Message success header='Client changes successfully saved' />
            </Container>)
        }
    }

    render() {
        var breadcrumbString = 'Create New Client'
        var saveButtonString = 'Create Client'
        if (this.props.isEditing) {
            breadcrumbString = this.props.clientToEdit.clientInfo.companyName
            saveButtonString = 'Save Changes'
        }
        return (
            <Container>
                <br />
                <Breadcrumb size='large'>
                    <BreadcrumbSection onClick={this.props.onBackClicked}>My Clients</BreadcrumbSection>
                    <BreadcrumbDivider icon='right chevron'/>
                    <BreadcrumbSection active>{breadcrumbString}</BreadcrumbSection>
                </Breadcrumb>
                <br /><br />
                <Menu tabular>
                    {this.renderClientInfo()}
                    {this.renderClientDocs()}
                    {this.renderAudit()}
                    {this.renderBusinessDev()}
                    {this.renderOperations()}
                    {this.renderCorpSec()}
                    {this.renderAccounting()}
                    {this.renderTax()}
                    {this.renderAuditTrail()}
                    {this.renderDownload()}
                </Menu>
                {this.renderSuccessMessage()}
                {this.renderTopButtonsForClientInfo(saveButtonString)}
                <br />
                {this.renderContent()}
                <br /><br />
                {this.renderBottomButtons(saveButtonString)}
                <br /><br /><br /><br /><br /><br /><br /><br />
            </Container>
        )
    }

    handleClientInfoStateChange = state => clientInfoDict = state
    handleAuditStateChange = state => auditDict = state
    handleBusinessDevStateChange = state => businessDevDict = state
    handleOperationsStateChange = state => operationsDict = state
    handleCorpSecStateChange = state => corpSecDict = state
    handleOutsourcedAccountingStateChange = state => outsourcedAccountingDict = state
    handleTaxFSStateChange = state => taxFSDict = state

    handleMenuItemClicked(e) {
        switch (e.target.innerText) {
            case "Client Info":
                this.setState({activeItem: "info"})
                break
            case "Documents":
                this.setState({activeItem: "docs"})
                break
            case "Audit":
                this.setState({activeItem: "audit"})
                break
            case "Business Development":
                this.setState({activeItem: "businessDev"})
                break
            case "Operations":
                this.setState({activeItem: "operations"})
                break
            case "Corp Sec":
                this.setState({activeItem: "corpSec"})
                break
            case "Outsourced Accounting":
                this.setState({activeItem: "outsourcedAccounting"})
                break
            case "Tax And FS":
                this.setState({activeItem: "taxFS"})
                break
            case "Audit Trail":
                this.setState({activeItem: "auditTrail"})
                break
            default:
                break
        }
    }

    handleDownloadClicked() {
        var dict = clientInfoDict
        if (dict.companyName == null) {
            dict.companyName = ''
        }
        if (dict.roc == null) dict.roc = ''        
        if (dict.mailingAddress == null) dict.mailingAddress = ''
        if (dict.registeredAddress == null) dict.registeredAddress = ''
        if (dict.roc == null) dict.roc = ''
        if (dict.phone1 == null) dict.phone1 = ''
        if (dict.phone2 == null) dict.phone2 = ''
        if (dict.fax == null) dict.fax = ''

        let string = `Client Information Update – ${dict.companyName}.docx`
        Packer.toBlob(doc(dict)).then((blob) => {
            saveAs(blob, string)
        })
        this.props.userAuditTrailDownloadClientInfoForm(this.props.user._id, { companyName: dict.companyName }, (success) => {})
    }

    handleSaveClicked() {
        if (this.props.isEditing) {
            const trail = this.props.clientToEdit.auditTrail
            var newEditedBy = trail.editedBy
            var newEditedAt = trail.editedAt
            
            if (!pushedAuditTrail) {
                newEditedBy.push(this.props.user.name)
                newEditedAt.push(Date())
                pushedAuditTrail = true
            }
            const combinedDict = {
                clientInfo: clientInfoDict, clientDocs: clientDocsDict, audit: auditDict, businessDev: businessDevDict, operations: operationsDict, corpSec: corpSecDict, 
                outsourcedAccounting: outsourcedAccountingDict, taxFS: taxFSDict, 
                auditTrail: { createdAt: trail.createdAt, createdBy: trail.createdBy, editedBy: newEditedBy, editedAt: newEditedAt }
            }
            combinedDict['_id'] = this.props.clientToEdit._id
            this.props.editClient(combinedDict, (success) => {
                if (success) {
                    this.props.userAuditTrailEditClient(this.props.user._id, { companyName: clientInfoDict.companyName }, (success) => {
                        // let str = clientInfoDict.companyName.slice(0)
                        // this.resetDicts()
                        // pushedAuditTrail = false
                        // this.resetDicts()
                        // this.props.onEditSuccess(str)
                        this.setState({ showEditClientSuccess: true })
                    })
                } else {
                    alert('Failure editing client, please try again')
                }
            })
        } else {
            const combinedDict = {
                clientInfo: clientInfoDict, audit: auditDict, businessDev: businessDevDict, operations: operationsDict, corpSec: corpSecDict, 
                outsourcedAccounting: outsourcedAccountingDict, taxFS: taxFSDict, auditTrail: { createdBy: this.props.user.name, createdAt: Date() }
            }
            this.props.createClient(combinedDict, (success) => {
                if (success) {
                    this.props.userAuditTrailCreateClient(this.props.user._id, { companyName: clientInfoDict.companyName }, (success) => {
                        this.resetDicts()
                        this.props.onCreateSuccess()
                    })
                } else {
                    alert('Failure creating client, please try again')
                }
            })
        }
    }

    handleCancelClicked() {
        this.resetDicts()
        this.props.onCancel()
    }

    handleUpdateClientDocs(docs, recordID) {
        clientDocsDict = docs
        this.setState({ keyForClientDocs: Date.now(), docsRedirect: 'viewDoc', recordIDRedirect: recordID })
    }

    handleResetDocsRedirect() {
        this.setState({ docsRedirect: ''})
    }

    resetDicts() {
        clientInfoDict = { remarksInfo: "" }
        clientDocsDict = {}
        auditDict = { remarks: "" }
        businessDevDict = { remarks: "" }
        operationsDict = { remarks: "" }
        corpSecDict = { remarks: "" }
        outsourcedAccountingDict = { remarks: "" }
        taxFSDict = { remarks: "" }
    }
}

function mapStateToProps({ client, user }) {
    return { client, user }
}

function getPermissionsForDept(dept, permissions) {
    if (permissions == null) {
        return []
    }
    var arr = []
    for (let permission of permissions) {
        const dep = permission.key.split('-')[1]
        if (dep === dept) {
            arr.push(permission)
        }
    }
    return arr
}

export default connect(mapStateToProps, actions)(NewClient)