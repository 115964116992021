import React, { Component } from "react"
import { connect } from "react-redux"
import * as actions from "../../actions"

import { MenuItem, Menu } from 'semantic-ui-react'

class NavBar extends Component {
    handleLogout = () => {
        window.localStorage.setItem('verified2FA', null)
        this.props.logout()
    }

    renderStaffItem() {
        if (isAdmin(this.props)) {
            return (<MenuItem name='users' active={this.props.activeItem === 'users'} onClick={() => { this.props.onChangeItem("users")}}>Staff</MenuItem>)
        } 
    }

    renderRoleItem() {
        if (isAdmin(this.props)) {
            return (<MenuItem name='roles' active={this.props.activeItem === 'roles'} onClick={() => { this.props.onChangeItem("roles")}}>Roles</MenuItem>)
        } 
    }

    renderContent() {
        return (
            <Menu>
                <MenuItem><img alt='logo' src='/fmd-logo.png' /></MenuItem>
                <MenuItem name='clients' active={this.props.activeItem === 'clients'} onClick={() => { this.props.onChangeItem("clients" )}}>Clients</MenuItem>
                <MenuItem name='documents' active={this.props.activeItem === 'documents'} onClick={() => { this.props.onChangeItem("documents" )}}>Documents</MenuItem>
                {this.renderStaffItem()}
                {this.renderRoleItem()}
                <MenuItem name='logout' onClick={this.handleLogout}>Logout</MenuItem>
            </Menu>
        )
    }

     render() {
        return (
            <div>
                {this.renderContent()}
            </div>
        )
     }
}

function mapStateToProps({ user }) {
    return { user }
}

function isAdmin(props) {
    if (props.user == null)
        return false
    if (props.user.role == null) 
        return false
    if (props.user.role.name == null) 
        return false
    if (props.user.role.name.toLowerCase().includes("admin"))
        return true
}

export default connect(mapStateToProps, actions)(NavBar)